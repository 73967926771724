import React from 'react';
import App from './App';
import Company from './Pages/Company';
import Term from './Pages/Term';
import Privacy from './Pages/privacy';
import Link from './Pages/Link';
import Vacancy from './Pages/Vacancy/Index';
import reportWebVitals from './reportWebVitals';
import ReactDOM from "react-dom/client";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Worker from './Pages/Worker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/company" element={<Company />}></Route>
      <Route path="/worker" element={<Worker />}></Route>
      <Route path="/term" element={<Term />}></Route>
      <Route path="/privacy" element={<Privacy />}></Route>
      <Route path="/link" element={<Link />}></Route>
      <Route path="/vacancy" element={<Vacancy />}></Route>

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
