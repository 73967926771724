import React from 'react';
import Navigation from '../../Components/Vacancy/Nav';
import Footer from '../../Components/Footer';
import CardVacancy from '../../Components/Vacancy/Card';
import Mark from '../../Assets/Images/mark.svg'

import BannerImage from '../../Assets/Images/woman.png';

function Vacancy(){
    return(
        <div>
            <Navigation></Navigation>
            <div className="banner">
                <div className="content">
                    <h3>Kami Hadir Untuk<br/>Anda</h3>
                    <p>IOWORK adalah tempat dimana penyedia pekerjaan dan pencari kerja bertemu secara langsung tanpa batasan. Masing-masing penyedia pekerjaan dan pencari kerja dapat memilih untuk melakukan  kemitraan kerja dengan masing-masing pihak.</p>
                </div>
                <div className="bannerImage">
                    <img src={BannerImage} alt="Banner"></img>
                </div>
            </div>
            <div className="containerVacancy">
                <div className="searchBox">
                    <div className="text">
                        <h4>Cari Pekerjaan Berdasarkan Daerah, Kota, dan Perusahaan</h4>  
                    </div>
                    <div className="innerBox">
                        <div className="inputPage">
                            <input placeholder="Daerah, Kota, Perusahaan..." />
                            <img src={Mark} alt="Mark" />
                        </div>
                        <button >Cari</button>
                    </div>
                </div>
                <div className="contentVacancy">
                    <h2>Job Terbaru</h2>
                    <div className="cardBox">
                        <CardVacancy></CardVacancy>
                        <CardVacancy></CardVacancy>
                        <CardVacancy></CardVacancy>
                        <CardVacancy></CardVacancy>
                        <CardVacancy></CardVacancy>
                        <CardVacancy></CardVacancy>
                        <CardVacancy></CardVacancy>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Vacancy;