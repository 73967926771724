import logo from './logo.svg';
import './Assets/Scss/App.scss';

// import Pages
import Homepage from './Pages/Home';
import Navigation from './Components/Nav';
import Footer from './Components/Footer';

import Circle from'./Assets/Images/assetCircle.png';

function App() {
  return (
    <div className="mainBackground">
      <Navigation></Navigation>
      <Homepage></Homepage>
      <Footer></Footer>
    </div>
  );
}

export default App;
