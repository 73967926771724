import React from "react";
import ImgVaccancy from '../../Assets/Images/vac1.png';
import Map from '../../Assets/Images/map.svg';

function CardVacancy(){
    return(
        <div className="cardVacancy">
            <img src={ImgVaccancy} alt="Card"/>
            <div className="text">
            <h4>12-Januari-2023</h4>
            <h3>Housekeeping, Bartender, dll.</h3>
            <div className="location">
                <img src={Map} alt="Map"/>
                <div className="text">
                    <h4>Hotel Pamoyanan - Kota Bogor</h4>
                </div>
            </div>
            <button>Lihat Detail</button>
            </div>
        </div>
    );
}

export default CardVacancy;