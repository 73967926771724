
import React from 'react'
import styles from "../Assets/Scss/link.module.css";
// import Helmet from "react-helmet";
import "../Assets/bootstrap/css/bootstrap.min.css";
import { Image,Row,Col,Container,Button } from 'react-bootstrap';
import ImgLogo from '../Assets/Images/logoApps.png';
import ImgIG from '../Assets/Images/ig.png';
import ImgFB from '../Assets/Images/fb.png';
import ImgLinked from '../Assets/Images/linkid.png';
import ImgYT from '../Assets/Images/yt.png';


function LinkPage() {
    return ( 
        <div  className={styles.bg}>  
            {/* Title */}
            {/* <div>
                <Helmet>
                    <title>{title ? title : defaultTitle}</title>
                </Helmet>
            </div> */}
            {/* Last Title */}
            <div>
                <div>
                <Container className={styles.headLink}>
                    <Row>
                            <Col>
                                <Image  className={styles.cardLogo} src={ImgLogo} width={100}/>
                                <p className="text-white mt-2 text-center">IOWork Indonesia</p>
                            </Col>
                    </Row>
                    <Row className="justify-content-center" style={{ marginTop: 20,paddingBottom:10 }}>
                            <a
                            href="https://www.instagram.com/iowork.indonesia"
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ "margin-right": "15px" }}
                            >
                            {" "}
                            <Image  className={styles.cardLogo} src={ImgIG} width={25}/>
                            </a>
                            <a 
                            href="https://www.facebook.com/iowork.2.0"
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ "margin-right": "15px" }}>
                            {" "}
                            <Image  className={styles.cardLogo} src={ImgFB} width={25}/>
                            </a>
                            
                            <a
                            href="https://www.linkedin.com/company/iowork-indonesia/about"
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ "margin-right": "15px" }}
                            >
                            {" "}
                            <Image  className={styles.cardLogo} src={ImgLinked} width={25}/>
                            </a>
                            <a
                            href="https://www.youtube.com/channel/UCa6dvhFsuiZfN_Q2eF6xLLw"
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ "margin-right": "15px" }}
                            >
                            <Image  className={styles.cardLogo} src={ImgYT} width={25}/>
                            </a>
                    </Row>
                    <Row>
                    <Col>
                    <a
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.app.iowork"
                        target="_blank"
                        style={{ textDecoration: "none", border:'trasnparent' }}
                    >
                            <Button className={styles.button}>
                                PlayStore
                            </Button>
                        </a>      
                    </Col>  
                    </Row>
                    <Row>
                    <Col>
                    <a
                        rel="noreferrer"
                        href="https://apps.apple.com/us/app/iowork/id1637939995"
                        target="_blank"
                        style={{ textDecoration: "none", border:'trasnparent' }}
                    >
                            <Button className={styles.button}>
                                AppStore
                            </Button>
                        </a>      
                    </Col>  
                    </Row>
                    <Row>
                    <Col>
                    <a
                        rel="noreferrer"
                        href="https://iowork.id/worker"
                        target="_blank"
                        style={{ textDecoration: "none", border:'trasnparent' }}
                    >
                            <Button className={styles.button}>
                                Untuk Pekerja
                            </Button>
                        </a>      
                    </Col>  
                    </Row>
                    <Row>
                    <Col>
                    <a
                        rel="noreferrer"
                        href="https://iowork.id/company"
                        target="_blank"
                        style={{ textDecoration: "none", border:'trasnparent' }}
                    >
                            <Button className={styles.button}>
                                Untuk Perusahaan
                            </Button>
                        </a>      
                    </Col>  
                    </Row>
                    <Row>
                    <Col>
                    <a
                        rel="noreferrer"
                        href="https://api.whatsapp.com/send/?phone=628118888603&text&type=phone_number&app_absent=0"
                        target="_blank"
                        style={{ textDecoration: "none", border:'trasnparent' }}
                    >
                            <Button className={styles.button}>
                            WhatsApp
                            </Button>
                        </a>      
                    </Col>  
                    </Row>
                
                    <Row  className="justify-content-center text-center">
                    <Col style={{ bottom:'10px !important',marginTop:50,position:'absolute', 'font-size':"12px"}}>
                        <span className="textFooterEmpat text-white">
                            All Right Reserved - PT Mega Kreasi Teknologi , 2022.
                        </span>
                    </Col>
                    </Row>
                </Container>
                </div>
            </div>
        </div>
     );
}



export default LinkPage;