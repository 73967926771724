import React, { useRef } from 'react';
import Navigation from '../Components/Nav';

import BannerImage from '../Assets/Images/woman.png';
import BannerPerusahaan from '../Assets/Images/perusahaan.png';
import BannerUser from '../Assets/Images/user.png';
import AboutAssets from '../Assets/Images/about.png';
import Tujuan from '../Assets/Images/tujuan.png';
import { Link } from "react-router-dom";
import Icon1 from '../Assets/Images/rekruitement.png';
import Icon2 from '../Assets/Images/task.png';
import Icon3 from '../Assets/Images/chart.png';
import Icon4 from '../Assets/Images/loc.png';
import Icon5 from '../Assets/Images/open.png';
import Icon6 from '../Assets/Images/time.png';
import Icon7 from '../Assets/Images/loc.png';
import Icon8 from '../Assets/Images/pay.png';

import P1 from '../Assets/Images/p1.png';
import P2 from '../Assets/Images/p2.png';
import P3 from '../Assets/Images/p3.png';
import P4 from '../Assets/Images/p4.png';
import P5 from '../Assets/Images/p5.png';
import P6 from '../Assets/Images/p6.png';

import AssetCta from '../Assets/Images/phoneassets.png';
import AssetCtaBg from '../Assets/Images/assetCtaBg.png';
import Teamwork from '../Assets/Images/teamwork.png';


function Homepages() {    const tujuan = useRef();
    const tentang = useRef();
    const penawaran = useRef();
    const home = useRef();

    function linkTujuan() {
        tujuan.current.scrollIntoView({ behavior: "smooth" });
    }
    function linktTentang() {
        tentang.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkPenawaran() {
        penawaran.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkHome() {
        home.current.scrollIntoView({ behavior: "smooth" });
    }

    return ( 
        <div>
            <Navigation
            tujuan={linkTujuan}
            tentang={linktTentang}
            penawaran={linkPenawaran}
            home={linkHome}
            ></Navigation>

            <div className="banner" ref={home}>
                <div className="content">
                    <h3>Kami Hadir Untuk<br/>Anda</h3>
                    <p>IOWORK adalah tempat dimana penyedia pekerjaan dan pencari kerja bertemu secara langsung tanpa batasan. Masing-masing penyedia pekerjaan dan pencari kerja dapat memilih untuk melakukan  kemitraan kerja dengan masing-masing pihak.</p>
                </div>
                <div className="bannerImage">
                    <img src={BannerImage}></img>
                </div>
            </div>
            
            

            <div className="sectionAbout" ref={tentang}>
                <div className="sectionBox">
                    <div className="sectionItems">
                        <img src={BannerPerusahaan} />
                        <div>
                            <h4>Solusi Untuk Perusahan</h4>
                            <p>Menemukan talenta berkualitas Meningkatkan efektifitas dan efisiensi perusahaan</p>
                            <a className="primary button" href="/company">Penawaran
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.34961 12H20.3496M20.3496 12L14.3496 6M20.3496 12L14.3496 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </a>
                        </div>
                    </div>
                    <div className="sectionItems">
                        <img src={BannerUser} />
                        <div>
                            <h4>Solusi Untuk Pekerja</h4>
                            <p>Menemukan pekerjaan disekitar dan mendapatkan penghasilan tak terbatas</p>
                            <Link className="primary button light" to="/worker">Penawaran
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.34961 12H20.3496M20.3496 12L14.3496 6M20.3496 12L14.3496 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h3 className="sectionTitle">Tentang Kami</h3>
                    <div className="grid">
                        <div className="box">
                            <img className="f-w" src={AboutAssets}></img>
                        </div>
                        <div className="box">
                            <p>Kami adalah perusahaan aplikasi yang berfokus pada menciptakan konsep baru dari market place yang kami sebut <b>"MARKET PLACE KERJA"</b> dimana kami mengumpulkan <b>PENYEDIA PEKERJAAN</b> dan <b>PENCARI KERJA</b> secara langsung dalam satu platform.</p>
                            <p>Penyedia kerja dan pencari kerja dapat saling bertukar informasi, dimana penyedia kerja dapat memilih langsung partnernya melalui talent pool yang kami sediakan sedangkan partner dapat memilih pekerjaan yang anda inginkan berdasarkan fleksibilitas waktu anda.</p>
                        </div>
                    </div>
                </div>
            </div>

        <div className="sectionclean" ref={tujuan}>
            <div className="container smallContainer">
                <h3 className="sectionTitle">Tujuan Kami</h3>
                <br/>
                <div className="grid">
                    <div className="box">
                        <img className="f-w" src={Tujuan}></img>
                    </div>
                    <div className="box">
                        <p>Mengurangi jumlah pengangguran dengan menciptakan kesempatan kerja sekaligus memberikan solusi bagi perusahaan dalam pertumbuhan industri kerja.</p>
                    </div>
                </div>
            </div>
            <div className="container no-padding">
                <div className="grid">
                    <div className="box tujuanItem">
                        <img src={Icon1}></img>
                        <h5>Proses rekrutmen dan penggajian yang simple</h5>
                    </div>
                    <div className="box tujuanItem">
                        <img src={Icon2}></img>
                        <h5>Mudah mencari pekerja</h5>
                    </div>
                    <div className="box tujuanItem">
                        <img src={Icon3}></img>
                        <h5>Produktivitas tinggi dan Efisiensi</h5>
                    </div>
                    <div className="box tujuanItem">
                        <img src={Icon4}></img>
                        <h5>Kerja dekat rumah</h5>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="grid">
                    <div className="box tujuanItem">
                        <img src={Icon5}></img>
                        <h5>Kesempatan membuktikan diri</h5>
                    </div>
                    <div className="box tujuanItem">
                        <img src={Icon6}></img>
                        <h5>Bekerja sesuai waktu yang di inginkan</h5>
                    </div>
                    <div className="box tujuanItem">
                        <img src={Icon7}></img>
                        <h5>Kerja di beberapa tempat</h5>
                    </div>
                    <div className="box tujuanItem">
                        <img src={Icon8}></img>
                        <h5>Dibayar langsung hari itu juga</h5>
                    </div>
                </div>
            </div>
        </div>


        <div className="sectionclean blueBg" ref={penawaran}>
            <div className="container">    
                <div className="grid">
                    <div className="col-small">
                       <div className="leftTitle">
                            <img src={Teamwork}></img>
                            <h2>Penawaran</h2>
                       </div>
                    </div>
                    <div className="col-large">
                        <div className="grid">
                            <div className="card">
                                <img src={P1}></img>
                                <p>Mengurangi Biaya SDM hingga lebih dari 30% tanpa mengurangi jumlah tenaga kerja yang artinya tingkat produktivitas 100%.</p>
                            </div>
                            <div className="card">
                                <img src={P2}></img>
                                <p>Membantu memastikan seluruh pencari kerja/partner tidak mendapat pengurangan kompensasi dan hak tunjangan sosial.</p>
                            </div>
                            <div className="card">
                                <img src={P3}></img>
                                <p>Membantu pencari kerja untuk memiliki kesempatan menunjukkan kemampuan kerjanya tanpa melalui proses rekrutmen yang berbelit-belit.</p>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="card">
                                <img src={P4}></img>
                                <p>Tidak menarik biaya apapun dari perusahaan/industri kerja. Mengubah SDM dari biaya tetap (Fixed Cost) menjadi biaya variable (Variable Cost).</p>
                            </div>
                            <div className="card">
                                <img src={P5}></img>
                                <p>Menciptakan kesempatan bagi perusahaan/industry kerja untuk memiliki system pekerjaan sesuai kebutuhan (On-demand Basis System).</p>
                            </div>
                            <div className="card">
                                <img src={P6}></img>
                                <p>Menciptakan kesempatan bagi perusahaan/industry kerja untuk memiliki system pekerjaan sesuai kebutuhan (On-demand Basis System).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="sectionCta" style={{ backgroundImage: "url(" + AssetCtaBg + ")" }}>
            <div className="container">    
                <div className="cta">
                    <div className="content">
                        <h1>Bergabunglah Bersama Kami</h1>
                        <p className="large">IOWORK memudahkanmu membuat pekerjaan seperti yang kamu inginkan dan mendapatkan pekerja sesuai dengan keahliannya.</p>
                    </div>
                    <div className="imgBanner">
                        <img src={AssetCta}></img>
                    </div>
                </div>
            </div>
        </div>

        </div>
     );
}

export default Homepages;