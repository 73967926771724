import React, { useRef } from 'react';

import Footer from '../Components/Footer';
import NavWorker from '../Components/NavWorker';


import HeroImage from '../Assets/Images/hero.png';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Stars from '../Assets/Images/stars.svg';
import User from '../Assets/Images/useravatar.png';

import Ps from '../Assets/Images/gp.png';
import As from '../Assets/Images/ap.png';
import Find from '../Assets/Images/find.png';

import Step1 from '../Assets/Images/step1.png';
import Step2 from '../Assets/Images/step2.png';
import Step3 from '../Assets/Images/step3.png';
import Step4 from '../Assets/Images/step4.png';
import Step5 from '../Assets/Images/step5.png';

import Fitur1 from '../Assets/Images/fitur1.svg';
import Fitur2 from '../Assets/Images/fitur2.svg';
import Fitur3 from '../Assets/Images/fitur3.svg';
import Fitur4 from '../Assets/Images/fitur4.svg';
import Fitur5 from '../Assets/Images/fitur5.svg';
import Fitur6 from '../Assets/Images/fitur6.svg';

import ImgTw1 from "../Assets/Images/tw1.png";
import ImgTw2 from "../Assets/Images/tw2.png";
import ImgTw3 from "../Assets/Images/tw3.png";
import ImgTw4 from "../Assets/Images/tw4.png";

import Hand1 from '../Assets/Images/hand.png';
import assetbenefit from '../Assets/Images/benefitBox.png';
import Cta from '../Assets/Images/ctaAssets.png';




function Worker() {
    const beranda = useRef();
    const tentang = useRef();
    const instruksi = useRef();
    const fitur = useRef();
    const keuntungan = useRef();
    const testimoni = useRef();

    function linkBeranda() {
        beranda.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkTentang() {
        tentang.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkInstruksi() {
        instruksi.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkFitur() {
        fitur.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkKeuntungan() {
        keuntungan.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkTestimoni() {
        testimoni.current.scrollIntoView({ behavior: "smooth" });
    }


    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2
    };

    return ( 
        <div>
            {/* <NavWorker></NavWorker> */}
            <NavWorker
            beranda={linkBeranda}
            tentang={linkTentang}
            intruksi={linkInstruksi}
            fitur={linkFitur}
            keuntungan={linkKeuntungan}
            testimoni={linkTestimoni}
            ></NavWorker>

            <div className="workerBanner" ref={beranda}>
                <div className="halfBanner">
                    <h1>Pekerjaan Tidak Pernah Se-Fleksibel Ini</h1>
                </div>
                <div className="bottomBanner">
                    <img className="phoneAssets" src={HeroImage}></img>
                    <br/>
                    <h4>Tersedia di</h4>
                    <div className="downloadApp">
                        <a href="https://play.google.com/store/apps/details?id=com.app.iowork" target="_blank"><img src={Ps}></img></a>
                        <a href="https://apps.apple.com/us/app/iowork/id1637939995" target="_blank"><img src={As}></img></a>
                    </div>
                </div>
            </div>

            <div className="gridContent" ref={tentang}>
                <div className="container">
                    <div className="contentBox">
                        <h2>Temukan Pekerjaan Dengan IOWORK</h2>
                        <p>Kami adalah perusahaan digital yang berfokus pada menghubungkan pengusaha dan mitra secara langsung melalui aplikasi smartphone kami. Kami juga menyediakan fitur pendukung lainnya untuk klien dan mitra kami untuk memenuhi kebutuhan mereka.</p>
                    </div>
                    <div className="assetsBox">
                        <img src={Find}></img>
                    </div>
                </div>
            </div>

            <div className="titleSection" ref={instruksi}>
                <h1>Bagaimana Cara Kerjanya?</h1>
            </div>

            <div className="gridContent">
                <div className="container rightContent">
                    <div className="contentBox smallWidth">
                        <h2>Unduh IOWORK di PlayStore dan Appstore</h2>
                        <p>Dengan mengunduh aplikasi IOWORK kamu sudah berada ditahap awal menuju penghasilan tak terbatas!</p>
                    </div>
                    <div className="assetsBox smallBox">
                        <img src={Step1}></img>
                    </div>
                </div>
            </div>

            <div className="gridContent">
                <div className="container">
                    <div className="assetsBox smallBox leftBox">
                        <img src={Step2}></img>
                    </div>
                    <div className="contentBox">
                        <h2>Unduh IOWORK di PlayStore dan Appstore</h2>
                        <p>Dengan mengunduh aplikasi IOWORK kamu sudah berada ditahap awal menuju penghasilan tak terbatas!</p>
                    </div>
                </div>
            </div>

            <div className="gridContent">
                <div className="container rightContent">
                    <div className="contentBox smallWidth">
                        <h2>Pilih pekerjaan yang tersedia </h2>
                        <p>Pilih task/pekerjaan yang tersedia disekitarmu dan dapatkan persetujuan secepatnya.</p>
                    </div>
                    <div className="assetsBox smallBox">
                        <img src={Step3}></img>
                    </div>
                </div>
            </div>

            <div className="gridContent">
                <div className="container">
                    <div className="assetsBox smallBox leftBox">
                        <img src={Step4}></img>
                    </div>
                    <div className="contentBox">
                        <h2>Mulai bekerja dan dapatkan penghasilan</h2>
                        <p>IOWORK memudahkanmu membuat pekerjaan seperti yang kamu inginkan dan mendapatkan pekerja sesuai dengan keahliannya.</p>
                    </div>
                </div>
            </div>

            <div className="gridContent">
                <div className="container rightContent">
                    <div className="contentBox smallWidth">
                        <h2>Tarik Penghasilan kapanpun</h2>
                        <p>Kamu bisa tarik penghasilanmu ketika sudah tersedia dibalance kapanpun!</p>
                    </div>
                    <div className="assetsBox smallBox">
                        <img src={Step5}></img>
                    </div>
                </div>
            </div>





            <div className="features" ref={fitur}>
                <div className="container">
                    <div className="content centerText">
                        <h1>Filtur Aplikasi</h1>
                    </div>
                </div>
                <div className="featureGrid">
                    <div className="featureItem">
                        <img src={Fitur1}></img>
                        <div>
                            <h3>CV Online</h3>
                            <p>Seluruh histori pekerjaan serta rating dan komen dari klien terdahulu akan dimasukkan dalam CV online-mu, sehingga kamu tidak perlu mengirimkan CV untuk pekerjaan yang akan datang</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={Fitur2}></img>
                        <div>
                            <h3>Pekerjaan berdasarkan lokasi</h3>
                            <p>Informasi menyeluruh atas pekerjaan yang sedang terbuka beserta detil informasi dari Deskripsi, catatan, lokasi, dan bayaran atas pekerjaan tersebut akan disediakan</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={Fitur3}></img>
                        <div>
                            <h3>Dompet Digital</h3>
                            <p>Bayaranmu akan dikirim ke dompet digital elektronikmu dimana kamu bisa menarik uang tersebut ke dalam rekening bank pribadimu</p>
                        </div>
                    </div>
                </div>
                <div className="featureGrid">
                    <div className="featureItem">
                        <img src={Fitur4}></img>
                        <div>
                            <h3>Proses lamaran secara langsung</h3>
                            <p>Seluruh pekerjaaan pilihanmu dapat langsung dilamar dan ketika kamu telah disetujui untuk bekerja maka kamu dapat langsung bekerja tanpa adanya proses interview</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={Fitur5}></img>
                        <div>
                            <h3>Tempat mempromosikan diri</h3>
                            <p>Kamu akan disediakan tempat berupa BIO-space dimana kamu dapat mempromosikan dirimu kepada klienmu yang akan datang</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={Fitur6}></img>
                        <div>
                            <h3>Pekerjaanmu tercatat di histori</h3>
                            <p>Seluruh informasi mengenai jadwal kerja kamu berikutnya, pekerjaan yang sedang kamu lamar akan tercatat automatis di histori aplikasi</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="testimoni darkBg" ref={testimoni}>
                <div className="testimoni__inner">
                    <div className="largeTitle">
                        <h2>Pengalaman mereka <br/> menggunakan iOWORK</h2>
                        <div className="assetsTitle">
                            <img src={Hand1}></img>
                        </div>
                    </div>
                    <div className="slideTestimoni">
                    <Slider {...settings}>
                        <div>
                            <div className="cardTestimoni DarkTestimoni">
                                <div className="topTestimoni">
                                    <div className="testiUser">
                                        <div>
                                        <img src={ImgTw1}></img>
                                        </div>
                                        <div className="profileInfo">
                                            <p>Feriawan Fnu</p>
                                            <span>Barista</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                    </div>
                                </div>
                                <h4>
                                    Bagi saya iowork sangat bagus ..untuk membantu generasi pemuda/pemudi Indonesia..walapun iowork belum menyeruruh di semua
                                    daerah Indonesia..tapi app iowork benar nyata dan membantu..masalah pembayaran bagi yg baru awalnya akan berpikir
                                    negatif ..tapi setelah ane jalanin.. Alhamdulillah ane bisa memberikan nafkah buat keluarga ane..jangan ragu kalau kalian mau
                                    maju kedepannya...app iowork bener membantu bagi kalian yg ingin kerja dan mendapatkan penghasilan....5 bintang buat IOWork dari
                                    ane.🙏🙏🙏
                                </h4>
                                
                            </div>
                        </div>

                        <div>
                            <div className="cardTestimoni DarkTestimoni">
                                <div className="topTestimoni">
                                    <div className="testiUser">
                                        <div>
                                        <img src="https://ui-avatars.com/api/?name=Lucky+Setiawan"></img>
                                        </div>
                                        <div className="profileInfo">
                                            <p>Lucky Setiawan</p>
                                            <span>Barista</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                    </div>
                                </div>
                                <h4>
                                    Alhamdulillah .dngan ada nya appl ini dlm mencri pekerjaan sangat
                                    mudah dlam bebrapa tahun ini sya ' dah merasakan hasil dr appl
                                    ini. smga appl ini tetap jaya .dn lebh merambah lagi data " job
                                    nya .
                                </h4>
                                
                            </div>
                        </div>

                        <div>
                            <div className="cardTestimoni DarkTestimoni">
                                <div className="topTestimoni">
                                    <div className="testiUser">
                                        <div>
                                        <img src={ImgTw2}></img>
                                        </div>
                                        <div className="profileInfo">
                                            <p>Meliarisana Samosir</p>
                                            <span>Barista</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                    </div>
                                </div>
                                <h4>
                                    Kapan dan dimana lagi mau dapat kerja sesuai waktu yang kita
                                    pnya???? Cuma iowork pastinya yang bisa kasih solusi 💃💃 TOP lah
                                    buat iowork .. memberi sesuai yang kita mau 👏👏 Terimakasih
                                    iowork ..semoga semakin jaya kedepannya 😘😘🎉🎉
                                </h4>
                                
                            </div>
                        </div>

                        <div>
                            <div className="cardTestimoni DarkTestimoni">
                                <div className="topTestimoni">
                                    <div className="testiUser">
                                        <div>
                                        <img src={ImgTw3}></img>
                                        </div>
                                        <div className="profileInfo">
                                            <p>kang Awie</p>
                                            <span>Barista</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                    </div>
                                </div>
                                <h4>Aplikasi yg sangat membantu dalam pembayaran aman, mudah dan praktis terima kasihh....</h4>
                                
                            </div>
                        </div>

                        <div>
                            <div className="cardTestimoni DarkTestimoni">
                                <div className="topTestimoni">
                                    <div className="testiUser">
                                        <div>
                                        <img src={ImgTw4}></img>
                                        </div>
                                        <div className="profileInfo">
                                            <p>Ricky Maenacky</p>
                                            <span>Barista</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                    </div>
                                </div>
                                <h4>Aplikasi yg sangat membantu dalam pembayaran aman, mudah dan praktis terima kasihh....</h4>
                                
                            </div>
                        </div>
                        
                        <div>
                            <div className="cardTestimoni DarkTestimoni">
                                <div className="topTestimoni">
                                    <div className="testiUser">
                                        <div>
                                        <img src="https://ui-avatars.com/api/?name=Ade+Budiono"></img>
                                        </div>
                                        <div className="profileInfo">
                                            <p>Ade Budiono</p>
                                            <span>Barista</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                    </div>
                                </div>
                                <h4>Aplikasi keren terobosan terbaru, dari segi pembayaranpun sangat aman. Mantap semoga kedepannya semakin maju.</h4>
                                
                            </div>
                        </div>

                        <div>
                            <div className="cardTestimoni DarkTestimoni">
                                <div className="topTestimoni">
                                    <div className="testiUser">
                                        <div>
                                        <img src="https://ui-avatars.com/api/?name=Rijal+Matin"></img>
                                        </div>
                                        <div className="profileInfo">
                                            <p>Rijal Matin</p>
                                            <span>Barista</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                        <img className="stars" src={Stars}></img>
                                    </div>
                                </div>
                                <h4>Sangat membantu untuk para pekerja yang tidak mau terikat sama waktu..</h4>
                                
                            </div>
                        </div>
                        
                        </Slider>
                    </div>
                    
                </div>
            </div>



            <div className="benefitworker" ref={keuntungan}>
                <div className="container">
                    <div className="contentTitle">
                        <h2>Keuntungan buat kamu</h2>
                        <img src={assetbenefit}></img>
                    </div>
                    <div className="contentBenefit">
                        <div className="contentBenefit__item">
                            <span>1</span>
                            <p>CV dan referensi kerja secara online</p>
                        </div>
                        <div className="contentBenefit__item">
                            <span>2</span>
                            <p>Dibayar langsung setelah diberi rating dan Review</p>
                        </div>
                        <div className="contentBenefit__item">
                            <span>3</span>
                            <p>Waktu kerja yang fleksibel dan Tidak dibutuhkan interview</p>
                        </div>
                        <div className="contentBenefit__item">
                            <span>4</span>
                            <p>Penawaran kerja dari klien secara langsung dan Memilih pekerjaan sesuai dengan keinginanmu secara jumlah bayaran dan lokasi</p>
                        </div>
                        <div className="contentBenefit__item">
                            <span>5</span>
                            <p>Kesempatan untuk memperoleh penghasilan lebih besar dari Upah Minimum</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="ctaBox">
                <div className="ctaContent">
                    <h1>Mari bergabung dan dapatkan pekerjaanmu segera</h1>

                    <h4>Download Aplikasi IOWORK di</h4>
                    <div className="downloadApp">
                        <a href="https://play.google.com/store/apps/details?id=com.app.iowork" target="_blank"><img src={Ps}></img></a>
                        <a href="https://apps.apple.com/us/app/iowork/id1637939995" target="_blank"><img src={As}></img></a>
                    </div>
                </div>
                <div className="ctaAssets">
                    <img src={Cta}></img>
                </div>
            </div>

            <Footer></Footer>
        </div>
     );
}

export default Worker;