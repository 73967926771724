import React from 'react';

import Navigation from '../Components/Nav';
import Footer from '../Components/Footer';

import Phone from '../Assets/Images/phone.png';


function Homepages() {
    return ( 
        <div>
            <Navigation></Navigation>
            <div className="bannerPage">
                <div>
                    <img src={Phone}></img>
                </div>
                <h1>Syarat dan<br/>Ketentuan</h1>
            </div>
            <div className="content">
                <div className="content_container">
                <p>Persetujuan Syarat dan Ketentuan dalam Aplikasi IOWork.id ini merupakan suatu hubungan hukum antara Pengguna dan IOWork.id dalam Penggunaan baik pada Aplikasi IOWork.id maupun dalam penggunaan Platform IOWork.id. Dengan mengunduh, memasang dan/atau menggunakan Aplikasi dan/atau Platform IOWork.id, maka Pengguna setuju menyatakan dirinya telah membaca, memahami, menerima dan menyetujui seluruh Syarat dan Ketentuan dalam IOWork.id. Untuk itu Pengguna diharapkan untuk membaca dengan teliti segala Syarat dan Ketentuan yang ada sebelum melakukan pengunduhan Aplikasi IOWork.id atau sebelum menggunakan Jasa aplikasi IOWork.id untuk pertama kali.</p>
<p>Apabila di kemudian hari Pengguna merasa tidak setuju dengan segala Syarat dan Ketentuan dalam Aplikasi IOWork.id ini, maka Pengguna dipersilahkan untuk membatalkan Akun dan menghapus secara permanen Aplikasi yang telah terunduh dan terpasang pada perangkat Pengguna. Pembatalan Akun dan penghapusan secara permanen Aplikasi Pengguna tidak akan melepaskan tanggung jawab Pengguna dari kewajiban dan akibat hukum yang telah timbul pada saat Pengguna melakukan transaksi yang telah selesai sebelumnya.</p>
<div>
<p><strong>A.</strong>IOWork.id merupakan suatu produk berbentuk Aplikasi milik PT. MEGA KREASI TEKNOLOGI.</p>
<p><strong>B.</strong>PT. MEGA KREASI TEKNOLOGI adalah perusahaan yang didirikan berdasarkan dan tunduk pada hukum yang berlaku di Indonesia. (untuk selanjutnya disebut sebagai "PERUSAHAAN"). PERUSAHAAN merupakan perusahaan penyedia jasa layanan berbasis teknologi informasi yang layanan utamanya adalah memberi wadah berupa Aplikasi IOWork.id untuk memudahkan dalam menghubungkan antara Klien dan Partner Kerja sehingga penyerapan kebutuhan pertner kerja dapat maksimal dan mengurangi pengangguran tenaga kerja. PERUSAHAAN dalam hal ini jelas bukan merupakan suatu Perusahaan Alih Daya (Out Sourcing), karena PERUSAHAAN tidak memiliki Partner sebagai pekerja yang didasarkan pada perjanjian kerja. Bahwa hubungan hukum yang timbul antara Partner dengan Klien dan/atau PERUSAHAAN adalah hubungan kerja sama. Dimana masing-masing Pihak diuntungkan dengan penggunaan Aplikasi.</p>
<p><strong>C.</strong>Aplikasi IOWork.id ini merupakan suatu piranti lunak berisi informasi dari Partner dan Klien yang berguna sebagai sarana untuk mencari, menemukan dan menghubungkan Partner sebagai pencari kerja dengan Klien sebagai Penyedia Lapangan Kerja.</p>
<p><strong>D.</strong>Partner adalah setiap orang-perorangan pencari kerja yang telah mengunduh dan menggunakan Aplikasi IOWork.id, kemudian sudah dewasa secara hukum, mampu melakukan Perjanjian Kerja Sama guna menghasilkan suatu barang dan/atau jasa, dan dalam hal ini sepakat setelah mengisi formulir serta syarat-syarat dalam Aplikasi maka akan terikat sebagai Partner dari IOWork.id dan/atau Klien.</p>
<p><strong>E.</strong>Klien adalah perorangan atau Perusahaan yang berbadan hukum atau tidak berbadan hukum dan telah terdaftar pada Aplikasi IOWork.id, serta membutuhkan Partner dalam melakukan Perjanjian Kerja Sama.</p>
<p><strong>F.</strong>Pengguna adalah Pengguna Aplikasi pada umumnya, Partner dan Klien.</p>
<p><strong>G.</strong>Pengguna dengan ini setuju untuk menggunakan rekening yang sudah ditunjuk oleh IOWork.id dalam melakukan transaksi.</p>
<p><strong>H.</strong>Akun adalah informasi mengenai username dan kata sandi yang dimiliki Pengguna.</p>
<p><strong>I.</strong>PERUSAHAAN hanya menampilkan pada Aplikasinya Perjanjian Kerja Sama sesuai dengan norma yang berlaku dalam masyarakat dan tidak akan bertentangan dengan hukum yang berlaku di Negara Kesatuan Republik Indonesia.</p>
<p><strong>J.</strong>Atas transaksi-transaksi yang dilakukan di luar mekanisme yang disediakan oleh IOWork.id, maka PERUSAHAAN melepas segala tanggung jawab dan tidak dapat dilakukan penuntutan dalam bentuk apapun.</p>
<p><strong>K.</strong>PERUSAHAAN memiliki kewenangan absolut untuk mengambil tindakan yang dianggap perlu terhadap Akun-Akun yang diduga dan/atau terindikasi melakukan penyalahgunaan, memanipulasi, dan/atau melanggar Syarat dan Ketentuan di PERUSAHAAN, dengan moderasi, menghentikan layanan, membatasi dalam pembuatan jumlah Akun, membatasi atau bahkan mengakhiri hak akses layanan Pengguna, maupun menutup Akun Pengguna tanpa adanya pemberitahuan terlebih dahulu kepada Pengguna.</p>
<p><strong>L.</strong>E-Voucher adalah fasilitas yang disediakan oleh PERUSAHAAN atas permohonan Klien dengan tujuan menjaga likuiditas keuangan Klien dan memberi kepastian pemenuhan kewajiban kepada Partner secara seketika setelah Perjanjian Kerja Sama telah selesai, sehingga terjadi lebih banyak penyerapan Partner. Permohonan Klien tersebut akan diverifikasi dan setelah mendapat persetujuan dari PERUSAHAAN, maka fasilitas akan disalurkan kepada Klien dalam bentuk E-Voucher.</p>
<p><strong>M.</strong>Perjanjian Kerja Sama adalah suatu Perjanjian untuk melakukan suatu pekerjaan yang diberikan oleh Klien kepada Partner. Yang dimulai saat Partner menekan tombol "mulai" pada Aplikasi sampai dengan selesai yaitu saat Klien menekan tombol "berhenti" pada Aplikasi.</p>
<p><strong>N.</strong>Deposit Partner adalah saldo minimum yang harus tersedia di dalam rekening ponsel Partner sebesar Rp. 50.000,- (lima puluh ribu rupiah) agar Partner dapat melakukan Perjanjian Kerja Sama.</p>
<p><strong>O.</strong>Deposit Klien adalah saldo minimum yang harus tersedia di PERUSAHAAN melalui transfer giro sebesar Rp. 500.000,- (lima ratus ribu rupiah) agar Klien dapat melakukan Perjanjian Kerja Sama.</p>
<p><strong>P.</strong>Rekening Partner adalah Dompet (Wallet) Partner yang digunakan sebagai rekening pada bank yang telah ditunjuk oleh PERUSAHAAN.</p>
<p><strong>Q.</strong>Kecelakaan Kerja adalah suatu peristiwa yang menimpa Partner pada saat dan/atau selama Perjanjian Kerja Sama berlangsung yang menimbulkan keadaan tertentu terhadap Partner.</p>
<p><strong>R.</strong>Atas segala permasalahan yang terjadi pada setiap transaksi, PERUSAHAAN memiliki kewenangan untuk melakukan penyelesaian yang dianggap tepat.</p>
<p><strong>S.</strong>Demi kepentingan PERUSAHAAN, maka dengan ini Pengguna memberikan izin kepada PERUSAHAAN untuk mengakses dan menyimpan setiap data-datanya yang ltelah diisi oleh Pengguna.</p>
<p><strong>T.</strong>Setiap komunikasi terkait dengan penggunaan Aplikasi IOWork.id, dapat menghubungi ke cs@iowork.id demi memperlancar serta mempermudah penggunaan.</p>
<p><strong>U.</strong>Demi memberi kepastian hukum kepada Pengguna, maka PERUSAHAAN memilih kantor Badan Arbitrase Nasional Indonesia ("BANI") di Jakarta sebagai lembaga penyelesaian masalah jika di kemudian hari terdapat suatu tuntutan hukum dari Pengguna maupun Pihak Ketiga terhadap PERUSAHAAN.</p>
</div>
<div>
<h5>1. SYARAT DAN KETENTUAN PENGGUNA SECARA UMUM</h5>
<ul>
<li><strong>1.1</strong>PERUSAHAAN berhak mengumpulkan informasi-informasi terkait data pribadi Pengguna, seperti nama, alamat surat elektronik dan nomor telepon seluler Pengguna pada saat mendaftar. Keakuratan, keaslian serta kelengkapan data pribadi adalah suatu kewajiban Pengguna untuk dipenuhi. Pengguna juga menyatakan persetujuannya untuk memberikan bukti-bukti identitas kepada PERUSAHAAN sebagai bukti keabsahan informasi. Jika ada perubahan pada data-data terkait identitas baik pada alamat surat elektronik, nomor telepon atau bahkan penutupan Akun, mohon setiap pembaharuan untuk dilakukan secara lengkap dan rinci melalui pemberitahuan kepada PERUSAHAAN. Selanjutnya, PERUSAHAAN akan memproses permintaan Pengguna atas adanya pembaharuan tersebut dalam jangka waktu selambat-lambatnya 14 (empat belas) hari sejak diberitahukan oleh Pengguna.</li>
</ul>
<ul>
<li><strong>1.2</strong>Pengguna hanya dapat menggunakan layanan akses pada Aplikasi maupun Platform IOWork.id ketika proses pendaftaran dianggap telah selesai dengan terlebih dahulu mengisi biodata serta memilih username dan kata sandi. Setelah pendaftaran berhasil, Pengguna selanjutnya dapat mengakses Akun Pengguna dan menggunakan layanan Aplikasi.</li>
</ul>
<ul>
<li><strong>1.3</strong>Subyek yang dapat mengakses layanan pada Aplikasi maupun Platform IOWork.id adalah hanya Pengguna dan Pengguna menjamin tidak akan memberi wewenang kepada pihak-pihak lain untuk mengakses layanan Aplikasi maupun Platform IOWork.id.</li>
</ul>
<ul>
<li><strong>1.4</strong>Pengguna harus menggunakan Aplikasi maupun Platform IOWork.id secara bijak dengan menghindari penyebaran atas konten-konten yang menyesatkan, mencemarkan nama baik orang lain maupun berisi ujaran kebencian. Selain itu, konten yang mengandung diskriminasi terhadap golongan, agama, suku, serta ras tertentu sangat dilarang oleh PERUSAHAAN.</li>
</ul>
<ul>
<li><strong>1.5</strong>Segala risiko yang timbul di kemudian hari atas informasi yang diberikan oleh Pengguna kepada PERUSAHAAN menjadi tanggung jawab Pengguna, tidak terbatas pada hak cipta, merek, desain industri, paten, serta hak desain tata letak industri atas suatu produk.</li>
</ul>
<ul>
<li><strong>1.6</strong>Pengguna dengan ini menyatakan persetujuannya untuk membebaskan PERUSAHAAN dan/atau seluruh personil di dalamnya dari segala tuntutan yang timbul akibat adanya kerugian termasuk tidak terbatas kepada pemalsuan identitas atau penggunaan situs-situs dan tautan lain yang terkait, hilangnya uang, barang dan lain-lain, sebagai akibat dari penggunaan Aplikasi maupun Platform yang disediakan oleh PERUSAHAAN.</li>
</ul>
<ul>
<li><strong>1.7</strong>Pengguna menjamin akan menggunakan layanan atas Aplikasi maupun platform IOWork.id sesuai dengan tujuan dan kegunaan dari Aplikasi. Penyalahgunaan Aplikasi maupun Platform IOWork.id untuk tujuan melakukan tindakan-tindakan yang bertentangan dengan hukum yang berlaku di Negara Kesatuan Republik Indonesia termasuk tetapi tidak terbatas kepada tindak pidana Penipuan, tindakan pemalsuan identitas yang menimbulkan ketidaknyamanan kepada pihak lain dilarang dalam Aplikasi maupun Platform IOWork.id.</li>
</ul>
<ul>
<li><strong>1.8</strong>Pengguna sepakat dan menyatakan persetujuannya atas segala syarat dan ketentuan lain yang mungkin ditambahkan di kemudian hari tanpa adanya pemberitahuan terlebih dahulu dari PERUSAHAAN pada Platform maupun Aplikasi.</li>
</ul>
<ul>
<li><strong>1.9</strong>Hubungan Kerjasama antara Partner dan Klien didasarkan pada ketentuan dan kebijakan yang berlaku pada Klien. Oleh karena itu, Partner dengan ini memahami peranan PERUSAHAAN sebagai penyedia Aplikasi hanya menjadi sarana penghubung antara Partner dan Klien serta menyatakan persetujuannya untuk mengikuti segala Ketentuan dan Persyaratan dari Klien.</li>
</ul>
<ul>
<li><strong>1.10</strong>Hukum yang mengatur dan berlaku terhadap penggunaan Aplikasi dan Platform IOWork.id tunduk dan sesuai dengan Peraturan Perundang-Undangan yang berlaku di Negara Kesatuan Republik Indonesia.</li>
</ul>
</div>
<div>
<h5>2. PARTNER</h5>
<ul>
<li><strong>2.1</strong>Partner menyatakan dan menjamin bahwa akan melaksanakan perjanjian kerjasama dengan iktikad baik serta mematuhi semua syarat dan ketentuan yang telah ditetapkan oleh Klien.</li>
</ul>
<ul>
<li><strong>2.2</strong>Partner menyatakan dan menjamin sebagai subyek hukum yang berhak untuk mengadakan perjanjian kerjasama dan mengikat berdasarkan ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia, telah dewasa, yaitu telah berusia 18 (delapan belas) tahun atau sudah menikah serta tidak berada di bawah perwalian.</li>
</ul>
<ul>
<li><strong>2.3</strong>Partner wajib menyertakan Nomor Induk Kependudukan pada Kartu Tanda Penduduk (&ldquo;KTP&rdquo;), Surat Keterangan Catatan Kepolisian (&ldquo;SKCK&rdquo;), pas foto setengah badan ukuran 3x4 (tiga kali empat) yang diambil 3 (tiga) bulan terakhir pada saat melakukan pendaftaran pada Aplikasi maupun Platform IOWork.id.</li>
</ul>
<ul>
<li><strong>2.4</strong>Atas penggunaan Aplikasi oleh Partner, maka PERUSAHAAN berhak memperoleh 10% (sepuluh persen) dari besarnya nilai transaksi Perjanjian Kerja Sama yang akan dipotong dari Deposit Rekening Partner.</li>
</ul>
<ul>
<li><strong>2.5</strong>Apabila Deposit pada Rekening Partner tidak mencukupi, maka Partner tidak dapat melakukan Perjanjian Kerja Sama dengan Klien sampai Deposit berada pada saldo minimum.</li>
</ul>
<ul>
<li><strong>2.6</strong>PERUSAHAAN menyediakan fasilitas asuransi di dalam Aplikasi berikut dengan syarat dan ketentuan dari perusahaan asuransi yang dapat dibaca oleh Partner sebelum Partner melakukan Perjanjian Kerja Sama dan memutuskan untuk menggunakan fasilitas asuransi tersebut. Apabila Partner memutuskan untuk menggunakan fasilitas asuransi, maka perhitungan biaya polis akan dibebankan kepada Partner. Dalam hal Partner melakukan Perjanjian Kerja Sama tanpa menggunakan fasilitas asuransi tersebut, maka setiap Kecelakaan Kerja yang dialami oleh Partner sepenuhnya menjadi tanggung jawab Partner. Mengenai Asuransi akan diterangkan lebih lanjut pada bab 4 dibawah.</li>
</ul>
<ul>
<li><strong>2.7</strong>Bagi Partner yang menggunakan fasilitas asuransi dan mengalami Kecelakaan Kerja, maka PERUSAHAAN hanya dapat membantu proses claim Partner kepada perusahaan asuransi setelah Partner memberikan bukti-bukti asli terkait dengan Kecelakaan Kerja tersebut kepada PERUSAHAAN, dengan catatan terkait klaim sepenuhnya akan mengikuti kebijakan perusahaan asuransi dan PERUSAHAAN tidak dapat memberikan jaminan apapun atas dipenuhinya klaim tersebut oleh perusahaan asuransi.</li>
</ul>
<ul>
<li><strong>2.8</strong>Sebagai bentuk kepedulian PERUSAHAAN kepada Partner, maka untuk keadaan tertentu berdasarkan pertimbangan PERUSAHAAN dapat memberikan santunan kepada Ahli Waris Partner yang mengalami Kecelakaan Kerja dalam hal meninggal dunia maksimal sebesar Rp. 10.000.000,- (sepuluh juta rupiah) atau untuk Partner yang mengalami luka berat maksimal sebesar Rp. 5.000.000,- (lima juta rupiah).</li>
</ul>
<ul>
<li><strong>2.9</strong>Partner menyatakan dan menyetujui apabila melakukan suatu tindakan yang menyebabkan kerugian bagi Klien, maka tindakan tersebut merupakan tanggungjawab Partner secara pribadi dan melepaskan PERUSAHAAN dari tanggung jawab tersebut.</li>
</ul>
<ul>
<li><strong>2.10</strong>Partner menyatakan dan menyetujui, apabila mengalami suatu tindakan yang mengakibatkan kerugian bagi diri Partner termasuk namun tidak terbatas pada adanya suatu tindak pidana yang dilakukan oleh Klien, maka Partner akan melepaskan PERUSAHAAN dari segala tuntutan dan gugatan secara hukum. PERUSAHAAN akan melakukan segala upaya yang dianggap cukup untuk melindungi dan menjaga Partner pada saat melakukan kerja sama.</li>
</ul>
<ul>
<li><strong>2.11</strong>Demi kemudahan dalam bertransaksi menggunakan Aplikasi, maka Partner disarankan untuk memiliki Rekening Tabungan pada bank yang ditunjuk oleh PERUSAHAAN (dalam hal ini Rekening Bank BRI). Adapun tata cara Pendaftaran Rekening Tabungan tersebut dapat dilakukan dengan cara mengunjungi dan mengikuti petunjuk yang telah tercantum di dalam situs resmi Bank BRI, yaitu www.bri.co.id.</li>
</ul>
</div>
<div>
<h5>3. KLIEN</h5>
<ul>
<li><strong>3.1</strong>Klien menyatakan dan menjamin atas Perjanjian Kerja Sama yang dilakukan dengan Partner tidak bertentangan dengan ketentuan hukum di Negara Kesatuan Republik Indonesia, dan melaksanakan Perjanjian tersebut dengan iktikad baik.</li>
</ul>
<ul>
<li><strong>3.2</strong>Klien menyatakan dan menjamin selama melakukan Perjanjian Kerja Sama, akan memperlakukan Partner secara adil dan tidak melakukan diskriminasi berdasarkan suku, agama, ras, dan golongan, serta tidak melakukan tindakan-tindakan yang tidak merugikan atau bahkan melakukan suatu tindak pidana terhadap Partner.</li>
</ul>
<ul>
<li><strong>3.3</strong>Apabila pada saat Perjanjian Kerja Sama berlangsung atau bahkan sudah berakhir, terjadi atau telah terjadi tindakan-tindakan yang tidak menyenangkan termasuk namun tidak terbatas pada tindak pidana berupa dugaan pelecehan seksual atau tindakan lain yang bertentangan dengan Peraturan yang berlaku, yang dilakukan oleh Klien kepada Partner, maka akan menjadi tanggung jawab Klien sepenuhnya.</li>
</ul>
<ul>
<li><strong>3.3</strong>Klien dengan ini melepaskan PERUSAHAAN dari segala Tuntutan dan/atau Gugatan hukum, apabila selama atau setelah Perjanjian Kerjasama, Partner melakukan tindakan-tindakan yang merugikan Klien termasuk namun tidak terbatas pada dugaan tindak pidana Pencurian, pengerusakan barang, pelecehan seksual atau tindakan lain, maka Klien tidak dapat meminta pertanggungjawaban hukum kepada PERUSAHAAN.</li>
</ul>
<ul>
<li><strong>3.4</strong>Klien menyepakati akan menyediakan profilnya dan bersedia untuk ditampilkan serta digunakan oleh Partner untuk keperluan Perjanjian Kerja Sama.</li>
</ul>
<ul>
<li><strong>3.5</strong>Deposit Klien diwajibkan bagi Klien untuk dimiliki guna melakukan Perjanjian Kerja Sama. Apabila nilai transaksi Perjanjian Kerja Sama melebihi dari saldo pada Deposit Klien, maka secara otomatis Perjanjian Kerja Sama tidak dapat dilakukan sampai dengan Deposit Klien diisi kembali oleh Klien.</li>
</ul>
<ul>
<li><strong>3.6</strong>Apabila Klien memperoleh E-Voucher dari PERUSAHAAN, maka kewajiban menempatkan Deposit Klien tidak diwajibkan.</li>
</ul>
<ul>
<li><strong>3.7</strong>Syarat dan ketentuan bagi Klien yang memperoleh E-Voucher dari PERUSAHAAN akan dibuatkan dalam Perjanjian tersendiri.</li>
</ul>
<ul>
<li><strong>3.8</strong>Klien menjamin dan memastikan untuk bertanggung jawab secara penuh atas setiap tindakan perwakilannya yang menjalankan dan menggunakan Aplikasi dan/atau dasbor untuk melakukan Perjanjian Kerja Sama dengan Partner. Perwakilan dari Klien adalah orang yang diberikan kewenangan dan/atau kuasa secara hukum untuk melakukan tindakan tersebut.</li>
</ul>
<ul>
<li><strong>3.9</strong>Klien sepakat dan menyetujui bahwa PERUSAHAAN berhak untuk membekukan, memblokir Akun bahkan menolak menyediakan layanan kepada Klien yang bidang usaha dan/atau kebijakan Klien dianggap tidak sesuai dengan ketentuan PERUSAHAAN. Keputusan PERUSAHAAN ini bersifat absolut dan tidak dapat digugat dalam bentuk apapun.</li>
</ul>
<ul>
<li><strong>3.10</strong>Klien sepakat dan setuju bahwa Klien tidak akan menggunakan data-data yang diperoleh dari PERUSAHAAN untuk tujuan selain yang dimaksudkan dalam Aplikasi maupun Platform IOWork.id yakni memberikan kesempatan peluang karir yang dimiliki oleh Klien kepada Partner secara langsung.</li>
</ul>
</div>
<div>
<h5>4. ASURANSI</h5>
<ul>
<li><strong>4.1</strong>Dalam hal ini, Asuransi adalah Asuransi BPJS Ketenagakerjaan Mandiri dan BPJS Kesehatan Mandiri.</li>
</ul>
<ul>
<li><strong>4.2</strong>Keberlakuan Asuransi hanya untuk Partner yang telah memilih untuk mengikutsertakan dirinya kepada fasilitas Asuransi pada saat sebelum menerima Perjanjian Kerja Sama.</li>
</ul>
<ul>
<li><strong>4.3</strong>Partner memahami dan menyetujui ketentuan Asuransi yang berlaku dan Partner tidak dapat meminta pertanggungjawaban kepada PERUSAHAAN atas ketentuan-ketentuan Asuransi tersebut.</li>
</ul>
<ul>
<li><strong>4.4</strong>Atas Asuransi ini, berlaku pembayaran polis sesuai ketentuan Asuransi untuk setiap transaksi Perjanjian Kerja Sama yang dipotong dari Deposit Partner.</li>
</ul>
<ul>
<li><strong>4.5</strong>Perjanjian Kerja Sama yang dapat diasuransikan adalah semua pekerjaan pada umumnya termasuk pada bidang konstruksi seperti tukang, mandor, supervisor tukang, engineering termasuk Partner harian yang bekerja menggunakan gondola.</li>
</ul>
</div>
                </div>
            </div>
            <Footer></Footer>

        </div>
     );
}

export default Homepages;