import React, {useState} from 'react';

import { Link } from "react-router-dom";
import Logo from '../../Assets/Images/logo.png';

function Navigations(props) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    return ( 
        <div className="navigation">
            <div className="navigation__inner">
                <div className="Brand">
                    <img src={Logo}></img>
                </div>
                <div className="mobMenu" onClick={() => toggleMenu()}>
                    <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.1328 0.15625H0.867188C0.725391 0.15625 0.609375 0.272266 0.609375 0.414062V2.47656C0.609375 2.61836 0.725391 2.73438 0.867188 2.73438H26.1328C26.2746 2.73438 26.3906 2.61836 26.3906 2.47656V0.414062C26.3906 0.272266 26.2746 0.15625 26.1328 0.15625ZM26.1328 20.2656H0.867188C0.725391 20.2656 0.609375 20.3816 0.609375 20.5234V22.5859C0.609375 22.7277 0.725391 22.8438 0.867188 22.8438H26.1328C26.2746 22.8438 26.3906 22.7277 26.3906 22.5859V20.5234C26.3906 20.3816 26.2746 20.2656 26.1328 20.2656ZM26.1328 10.2109H0.867188C0.725391 10.2109 0.609375 10.327 0.609375 10.4688V12.5312C0.609375 12.673 0.725391 12.7891 0.867188 12.7891H26.1328C26.2746 12.7891 26.3906 12.673 26.3906 12.5312V10.4688C26.3906 10.327 26.2746 10.2109 26.1328 10.2109Z" fill="#2C394B"/>
                    </svg>
                </div>
                <div  onClick={() => toggleMenu()} className={menuOpen ? 'right__menu showMenu' : 'right__menu'}>
                    <Link to="/">Beranda</Link>
                    <Link to="/">Cara Kerja</Link>
                    <Link to="/">Keuntungan</Link>
                    <Link to="/">Testimoni</Link>
                    <Link to="/">Klien</Link>
                    <Link to="/">Tentang</Link>
                    <a href="https://help.iowork.id" className="buttonNavigation" target="_taarget">Bantuan</a>
                </div>
            </div>
        </div>
     );
}

export default Navigations;