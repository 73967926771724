import React, { useRef } from 'react';

import Footer from '../Components/Footer';
import NavCompany from '../Components/NavCompany';

import Banner from '../Assets/Images/company.png';
import BannerImage from '../Assets/Images/bannerBg.png';
import Dashboard from '../Assets/Images/features.png';
import Partner from '../Assets/Images/partnerIcon.svg'
import F2 from '../Assets/Images/f2.svg'
import F3 from '../Assets/Images/f3.svg'
import F4 from '../Assets/Images/f4.svg'
import F5 from '../Assets/Images/f5.svg'
import F6 from '../Assets/Images/f6.svg'


import Bbanner from '../Assets/Images/bbanner.png'
import B1 from '../Assets/Images/b1.svg'
import B2 from '../Assets/Images/b2.svg'
import B3 from '../Assets/Images/b3.svg'
import B4 from '../Assets/Images/b4.svg'
import B5 from '../Assets/Images/b5.svg'
import B6 from '../Assets/Images/b6.svg'


import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Stars from '../Assets/Images/stars.svg';
import User from '../Assets/Images/useravatar.png';

import C1 from '../Assets/Images/c1.png';
import C2 from '../Assets/Images/c2.png';
import C3 from '../Assets/Images/c3.png';
import C4 from '../Assets/Images/c4.png';
import C5 from '../Assets/Images/c5.png';
import C6 from '../Assets/Images/c6.png';
import C7 from '../Assets/Images/c7.png';
import C8 from '../Assets/Images/c8.png';
import C9 from '../Assets/Images/c9.png';
import C10 from '../Assets/Images/c10.png';
import C11 from '../Assets/Images/c11.png';
import C12 from '../Assets/Images/c12.png';



function Company() {

    const carakerja = useRef();
    const keuntungan = useRef();
    const testimoni = useRef();
    const klien = useRef();
    const tentang = useRef();
    const beranda = useRef();

    function linkcarakerja() {
        carakerja.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkkeuntungan() {
        keuntungan.current.scrollIntoView({ behavior: "smooth" });
    }
    function linktestimoni() {
        console.log('asdasd')
        testimoni.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkklien() {
        klien.current.scrollIntoView({ behavior: "smooth" });
    }
    function linktentang() {
        tentang.current.scrollIntoView({ behavior: "smooth" });
    }
    function linkberanda() {
        beranda.current.scrollIntoView({ behavior: "smooth" });
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2
    };

    return ( 
        <div>
            {/* <NavCompany></NavCompany> */}
            <NavCompany
            beranda={linkberanda}
            carakerja={linkcarakerja}
            keuntungan={linkkeuntungan}
            testimoni={linktestimoni}
            klien={linkklien}
            tentang={linktentang}
            ></NavCompany>

            <div className="companyBanner" style={{background:'url(' + BannerImage + ')'}}>
                <div className="bannerInner">
                    <div className="content">
                        <h1>Menciptakan Pekerjaan Tanpa Batas</h1>
                        <p>IOWORK memudahkanmu membuat pekerjaan seperti yang kamu inginkan dan mendapatkan pekerja sesuai dengan keahliannya.</p>
                        <a href="https://api.whatsapp.com/send/?phone=628118888603&text&type=phone_number&app_absent=0" target="_blank">Hubungi Kami</a>
                    </div>
                    <div className="images">
                        <img src={Banner}></img>
                    </div>
                </div>
            </div>

            <div className="features" ref={carakerja}>
                <div className="container">
                    <div className="image">
                        <img src={Dashboard}></img>
                    </div>
                    <div className="content">
                        <h1>Kelola Semua Pekerjaan Dengan Satu Platform</h1>
                    </div>
                </div>
                <div className="featureGrid">
                    <div className="featureItem">
                        <img src={Partner}></img>
                        <div>
                            <h3>Menemukan Partner Pencari Kerja Melalui Laptop Dan Smartphone</h3>
                            <p>Klien akan disediakan dengan detail informasi dari para partner termasuk pengalaman, penilaian, dan komen dari klien terdahulu.</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={F2}></img>
                        <div>
                            <h3>Laporan Realtime Dan Transparan</h3>
                            <p>Seluruh laporan dari pekerjaan yang sedang berlangsung begitu juga dengan yang terdahulu dan yang akan datang dapat diakses secara real-time.</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={F3}></img>
                        <div>
                            <h3>Mencari Partner Berdasarkan Lokasi</h3>
                            <p>Seluruh partner yang terdaftar diklasifikasikan ke dalam lokasi mereka sehingga memudahkan seluruh klien untuk memilih dan mempekerjaan partner yang jaraknya dekat.</p>
                        </div>
                    </div>
                </div>
                <div className="featureGrid">
                    <div className="featureItem">
                        <img src={F4}></img>
                        <div>
                            <h3>Sistem Pembayaran Upah Digital</h3>
                            <p>Seluruh pembayaran kepada partner akan dilakukan secara langsung setelah mereka menyelesaikan tugasnya secara otomatis ke dalam dompet digital milik partner.</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={F5}></img>
                        <div>
                            <h3>Sistem Pengisian Deposit</h3>
                            <p>Keleluasaan untuk mengatur cash flow perusahaan dengan mempergunakan system pengisian deposit yang akan berefek juga terhadap penurunan biaya sumber daya manusia, serta pengelolaan anggaran SDM yang lebih terkontrol.</p>
                        </div>
                    </div>
                    <div className="featureItem">
                        <img src={F6}></img>
                        <div>
                            <h3>Mengatur Anggaran Sumber Daya Manusia</h3>
                            <p>Klien dapat merencanakan penempatan anggaran sumber daya manusia sebelumnya sesuai dengan kebutuhan untuk bermacam-macam departemen/lingkup pekerjaan.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="benefit" ref={keuntungan}>
                <div className="container">
                    <div className="assets">
                        <img src={Bbanner}></img>
                    </div>
                    <div className="content">
                        <h2>Keuntungan Sebagai Klien</h2>
                        <div className="benefitCard">
                            <div className="benefitItem">
                                <img src={B1}></img>
                                <h5>Terintegrasi dengan Smartphone dan Komputer/Laptop</h5>
                            </div>
                            <div className="benefitItem">
                                <img src={B2}></img>
                                <h5>Laporan Real Time</h5>
                            </div>
                        </div>
                        <div className="benefitCard">
                            <div className="benefitItem">
                                <img src={B3}></img>
                                <h5>Pencarian pekerja sesuai lokasi</h5>
                            </div>
                            <div className="benefitItem">
                                <img src={B4}></img>
                                <h5>Pembayaran dengan Digital Wallet</h5>
                            </div>
                        </div>
                        <div className="benefitCard">
                            <div className="benefitItem">
                                <img src={B5}></img>
                                <h5>Sistem pembayaran deposit</h5>
                            </div>
                            <div className="benefitItem">
                                <img src={B6}></img>
                                <h5>Mudah mengatur anggaran</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="testimoni">
                <div className="testimoni__inner" ref={testimoni}>
                    <h3>Testimoni Klien</h3>
                    <div className="slideTestimoni">
                        <Slider {...settings}>
                            
                        <div>
                            <div className="cardTestimoni">
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <h4>
                                    “IOwork merupakan solusi yang sangat efisien untuk membantu
                                    perusahaan untuk memetakan produktifitas karyawan dari segi
                                    waktu, yang akhirnya berimbas pada win win baik perusahaan
                                    maupun karyawan.”
                                </h4>
                                <img src="https://ui-avatars.com/api/?name=C+N"></img>
                                <p><b>Cornelius Niko</b> <br></br>General Manager Revive Hotel</p>
                            </div>
                        </div>
                        <div>
                            <div className="cardTestimoni">
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <h4>
                                    “ IOwork merupakan inovasi baru dalam dunia personalia yang
                                    efisien di jaman sekarang ini, di saat ekonomi terdampak
                                    oleh COVID-19 sistem kerjasama IOwork hadir sebagai jalan
                                    tengah.”
                                </h4>
                                <img src="https://ui-avatars.com/api/?name=R+P"></img>
                                <p><b>Andrew Suyata</b> <br></br>Assistant Hotel Manager, Red Planet Hotel</p>
                            </div>
                        </div>
                        <div>
                            <div className="cardTestimoni">
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <img className="stars" src={Stars}></img>
                                <h4>
                                    “ Sangat membantu sekali, mulai dari mencari pekerja,
                                    mendapatkan pekerja dan mengatur keuangan perusahaan.
                                    Terimakasih banyak IOWORK. MAJU TERUS. ”
                                </h4>
                                <img src="https://ui-avatars.com/api/?name=E+N"></img>
                                <p><b>Eko Nugroho</b> <br></br>TransJava Logistic</p>
                            </div>
                        </div>

                        </Slider>
                    </div>


                    <div className="listKlien" ref={klien}>
                        <h3>Klien Kami</h3>
                        <div className="klienGrid">
                            <a href="#">
                                <img src={C1}></img>
                            </a>
                            <a href="#">
                                <img src={C2}></img>
                            </a>
                            <a href="#">
                                <img src={C3}></img>
                            </a>
                            <a href="#">
                                <img src={C4}></img>
                            </a>
                            <a href="#">
                                <img src={C5}></img>
                            </a>
                            <a href="#">
                                <img src={C6}></img>
                            </a>
                            <a href="#">
                                <img src={C7}></img>
                            </a>
                            <a href="#">
                                <img src={C8}></img>
                            </a>
                            <a href="#">
                                <img src={C9}></img>
                            </a>
                            <a href="#">
                                <img src={C10}></img>
                            </a>
                            <a href="#">
                                <img src={C11}></img>
                            </a>
                            <a href="#">
                                <img src={C12}></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </div>
     );
}

export default Company;